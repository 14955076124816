import { observer } from 'mobx-react';
import { GetServerSidePropsContext } from 'next';
import Head from 'next/head';
import Script from 'next/script';
import { useEffect, useState } from 'react';
import BorderLeaves from '../components/BorderLeaves';
import { Box, BoxProps, Flex, Stack, Text } from '../components/Design';
import { ChevronRight } from '../components/Design/Icons';
import { colors } from '../components/Design/vars.css';
import MailingListForm from '../components/Forms/MailingListForm';
import PageLayout from '../components/Layout';
import InPageContainer from '../components/Layout/InPageContainer';
import LineBreakHandler from '../components/LineBreakHandler';
import LinkButton from '../components/LinkButton';
import PageTextBanner from '../components/PageTextBanner';
import ProductList from '../components/Product/ProductList';
import HomeHero from '../components/sections/HomeHero';
import InstagramSection from '../components/sections/InstagramSection';
import { useProductStore } from '../store/rootStoreProvider';
import { KnownProductTypes, companyName, specialRouteHrefs } from '../utils/const';
import { hexToRgbA } from '../utils/theme';
import { defaultMeta } from './_app';
import { getContentfulEntry } from './api/contentful/get-entry';
import { apiError } from './api/utils';
import {
  ContentfulAnnouncementData,
  HomePageContentfulData,
  PageContentfulData,
  getPageAnnouncement
} from './api/utils/contentful';
import { predefinedProductSearch } from './api/utils/shopifyApi';

export interface HomeProps {
  content: PageContentfulData<HomePageContentfulData | ContentfulAnnouncementData>;
}

const Home = ({ content }: HomeProps) => {
  const { pageTitle, pageDescription, keywords, pageDataCollection } = content || {};
  const { showAnnouncement, bannerBackgroundColor, bannerTextColor, announcement } =
    getPageAnnouncement(pageDataCollection);
  const { emailListFormHeading, emailListFormSubheading, instagramHeading, newListingsHeading, ...heroData } =
    (pageDataCollection?.items?.find(({ __typename }) => {
      return __typename === 'HomePage';
    }) as HomePageContentfulData | undefined) ?? {};
  const { getNewArrivalByCategory, newOrchidArrivals } = useProductStore();
  const [showReviews, setShowReviews] = useState(false);

  useEffect(() => {
    let mounted = true;
    if (!mounted) return;

    if (typeof window !== 'undefined') {
      setTimeout(() => {
        getNewArrivalByCategory(predefinedProductSearch.newOrchidArrivals, KnownProductTypes.orchid);
        console.log('Retrieved new arrivals');
      }, 1500);
    }

    return () => {
      mounted = false;
    };
  }, []);

  return (
    <>
      {/* TODO: Update head */}
      <Head>
        <title>{pageTitle ?? `${companyName} ~ Home`}</title>
        <meta name="description" content={pageDescription ?? defaultMeta.description} />
        {keywords && keywords.length > 0 && <meta name="keywords" content={keywords?.join(', ')} />}
      </Head>

      <PageLayout
        hasHero
        bannerInsert={
          showAnnouncement && (
            <PageTextBanner
              backgroundColor={bannerBackgroundColor?.name?.toLocaleLowerCase() as BoxProps<'div'>['color']}
              color={bannerTextColor?.name?.toLocaleLowerCase() as BoxProps<'div'>['color']}>
              {announcement}
            </PageTextBanner>
          )
        }>
        <Stack variant="pageStack" overflowX="hidden">
          <HomeHero {...heroData} />

          <InPageContainer size="xl">
            <Stack as="section" variant="sectionStack" justifyContent="center" marginBottom="4px">
              <Text as="h3" variant="sectionHeading" color="primary" width="fitContent" marginX="auto">
                {newListingsHeading ?? 'New Orchid Arrivals!'}
              </Text>
              <ProductList
                products={newOrchidArrivals?.slice(0, 8)}
                gridTemplateColumns={{ mobile: '2x', tablet: '4x', desktop: '4x' }}
                marginBottom="16px"
                defaultQty={8}
              />
              <Flex justifyContent="center">
                <LinkButton href={specialRouteHrefs?.plp({ categoryType: KnownProductTypes.orchid })} includeLoader>
                  <Text as="span" fontSize="lg">
                    View More
                  </Text>
                  <ChevronRight />
                </LinkButton>
              </Flex>
            </Stack>
          </InPageContainer>
          <Box>
            <Box
              as="section"
              position="relative"
              display="flex"
              justifyContent="flex-end"
              height={{ mobile: '300px', tablet: '350px', desktop: '400px' }}
              style={{
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center'
              }}>
              {true && (
                <Box
                  as="img"
                  width="full"
                  height="full"
                  position="absolute"
                  zIndex="hide"
                  alt=""
                  aria-hidden="true"
                  src="/assets/temp-images/cattleya-email-sub.webp"
                  style={{
                    objectFit: 'cover',
                    objectPosition: '100% center'
                    // height: resized?.height.toFixed(2),
                    // width: resized?.width.toFixed(2)
                  }}
                />
              )}
              {true && (
                <Box
                  as="div"
                  width="full"
                  height="full"
                  position="absolute"
                  zIndex="hide"
                  aria-hidden="true"
                  opacity={{ mobile: '1', tablet: '0.8' }}
                  backgroundColor="primary"
                  style={{
                    objectFit: 'cover',
                    objectPosition: '100% center'
                  }}
                />
              )}
              <BorderLeaves />
              <Flex
                alignItems="center"
                paddingX={{ mobile: '16px', tablet: '28px' }}
                justifyContent={{ mobile: 'center', tablet: 'flex-start' }}
                width={{ mobile: 'full', tablet: 'half' }}>
                <Stack textAlign={{ mobile: 'left', tablet: 'left' }}>
                  <Text
                    as="h3"
                    textAlign="inherit"
                    variant="unstyled"
                    fontSize={{ mobile: 'xxl', tablet: 'xxl', desktop: 'xxxxl' }}
                    color="white"
                    textTransform="capitalize"
                    style={{
                      textShadow: `0px 2px 10px ${hexToRgbA(colors.black, 0.45)}`,
                      lineHeight: '1em'
                    }}>
                    <LineBreakHandler string={emailListFormHeading ?? 'Sign up for email updates from Aeribella'} />
                  </Text>
                  <Text
                    as="h4"
                    variant="subheading"
                    textAlign="inherit"
                    color="white"
                    textTransform="capitalize"
                    style={{ textShadow: `0px 2px 10px ${hexToRgbA(colors.black, 0.45)}` }}>
                    <LineBreakHandler
                      string={
                        emailListFormSubheading ??
                        "Be the first to know when we're having a sale and when new inventory goes live!"
                      }
                    />
                  </Text>
                  <MailingListForm
                    inputID="homepage"
                    marginBottom="8px"
                    // style={{ maxWidth: '515px' }}
                    flipErrorColors
                    hideLabel
                    label="email"
                  />
                </Stack>
              </Flex>
              <BorderLeaves variant="bottomRight" />
            </Box>
            <Script
              src="https://static.elfsight.com/platform/platform.js"
              data-use-service-core
              onReady={() => {
                setShowReviews(true);
              }}></Script>
            <Box
              display={{ mobile: 'none', tablet: 'block' }}
              firstClassName="elfsight-app-121cd553-9987-4ebd-90b3-f42d30eb6e91"
              backgroundColor="gray"
              paddingY={showReviews ? { mobile: '48px' } : undefined}></Box>
          </Box>

          <InPageContainer>
            <Stack as="section" variant="sectionStack" justifyContent="center" marginY="20px">
              <Text as="h3" variant="sectionHeading" color="primary" width="fitContent" marginX="auto">
                {instagramHeading ?? 'Follow Aeribella On Instagram!'}
              </Text>
              <InstagramSection />
            </Stack>
          </InPageContainer>
        </Stack>
      </PageLayout>
    </>
  );
};

export async function getServerSideProps(ctx: GetServerSidePropsContext) {
  try {
    const homePageCMSContent = (await getContentfulEntry('homePage', true)) ?? null;

    // 10 Minutes Caching
    ctx.res.setHeader('Cache-Control', 'public, s-maxage=600,  stale-while-revalidate=60,  proxy-revalidate');
    return {
      props: {
        content: homePageCMSContent?.page ?? null
      }
    };
  } catch (e) {
    apiError({ errorInText: 'home page', error: e });

    return { props: {} };
  }
}

export default observer(Home);
