import { ProductData } from '../../types/store';
import { Box, BoxProps } from '../Design';
import ProductCard from './ProductCard';

interface ProductListProps extends Omit<BoxProps<'div'>, 'gap' | 'display'> {
  products?: ProductData[];
  defaultQty?: number;
}

const ProductList = ({ products, gridTemplateColumns, defaultQty = 16, ...props }: ProductListProps) => {
  if (!products || products?.length === 0) {
    const tiles = [];

    for (let index = 0; index < defaultQty; index++) {
      tiles.push(index + 1);
    }

    return (
      <Box
        display="grid"
        gap={['12px', '20px', '24px']}
        gridTemplateColumns={gridTemplateColumns ?? { mobile: '2x', tablet: '4x', desktop: '4x', hd: '5x' }}
        {...props}>
        {tiles?.map((card, index) => {
          return (
            <Box key={card + index}>
              <ProductCard data={undefined} isLoading={true} />
            </Box>
          );
        })}
      </Box>
    );
  }

  return (
    <Box
      display="grid"
      gap={['12px', '20px', '24px']}
      gridTemplateColumns={gridTemplateColumns ?? { mobile: '2x', tablet: '4x', desktop: '4x', hd: '5x' }}
      {...props}>
      {products?.map((card, index) => {
        return (
          <Box key={(card?.id as string) + index}>
            <ProductCard data={card} isLoading={!products} />
          </Box>
        );
      })}
    </Box>
  );
};

export default ProductList;
